import { HttpClient, HttpHeaders } from '@angular/common/http';
import { lastValueFrom, Observable, switchMap } from 'rxjs';
import { AuthService } from '../auth.service';
import { gcConfig } from '@gcConfig';
import { login } from '../interface/user';
import * as CryptoJS from 'crypto-js';
import { Injectable } from '@angular/core';
import { ChangePasswordRequest } from '../interface';
@Injectable()
export class AuthApiService {

  parsedBase64Key: any;
  constructor(private http: HttpClient, private auth: AuthService) {
    this.parsedBase64Key = CryptoJS.enc.Base64.parse(gcConfig.ENCRYPT_PASSWORD);
  }

  prepareValidateUserAPIPayload(loginForm: login) {
    let validateUserPayload = new URLSearchParams();
    validateUserPayload.append('grant_type', 'password');
    validateUserPayload.append('username', loginForm.email);
    validateUserPayload.append('password', this.passwordEncrypt(loginForm.password));
    return validateUserPayload;
  }
  passwordEncrypt(data: string): any {
    if (data === "")
      return data;
    data = data.toString();
    return CryptoJS.AES.encrypt(data, this.parsedBase64Key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
  }

  forgotPasswordAPI(data: any) {
    return lastValueFrom(this.http.post(`/gc/forgotpassword`, data, { responseType: 'text' }));
  }

  setPasswordAPI(data: any) {
    return lastValueFrom(this.http.post(`gc/setpassword`, data, { responseType: 'text' }));
  }

  changePasswordAPI(data: ChangePasswordRequest) {
    return lastValueFrom(this.http.post(`gc/changepassword`, data, { responseType: 'text' }));
  }

  oauthApi(data: login) {
    const payload = this.prepareValidateUserAPIPayload(data);
    const headers = new HttpHeaders({
      Authorization: 'Basic ' + btoa('MobileClientGC' + ':' + 'password'),
      'Content-Type': 'application/x-www-form-urlencoded',
      'siteURL': 'home'
    });
    return this.http.post(`oauth/token`, payload.toString(), { headers: headers })
  }
  getUserByEmailAPI(email: string) {
    return lastValueFrom(this.http.post(`gc/users/getuser?username=${email}`, null));
  }
  getUserByMail(email: string) {
    return this.http.post(`gc/users/getuser?username=${email}`, null);
  }

  loginUser(data: login): Observable<any> {
    return this.oauthApi(data).pipe(
      switchMap((res: any) => {
        this.auth.setToken(res.access_token);
        // Use the result from the first API call to make the second API call
        return this.getUserByMail(data.email);
      },
      ));
  }

  loadUserDataIfValidSession(): Promise<boolean> {
    if (this.auth.isAuthenticated()) {
      const promise = new Promise<boolean>((resolve, reject) => {
        const user = this.auth.currentUser
        this.getUserByEmailAPI(user?.email || '')
          .then((data: any) => {
            this.auth.setUser(data);
            resolve(true)
          }).catch((error) => {
            this.auth.logout();
            resolve(false)
            throw new Error('Internal server error');
          })
      });
      return promise;
    }
    return new Promise<boolean>((resolve, reject) => { resolve(false) })
  }
}