export const PageUrl = {
    MHS_GLOBAL_URL: 'https://www.mhsglobal.org/',
    INHERIT_INITIATIVE_URL: 'http://inheritinitiative.org/',
    ITOI_URL: 'http://www.itoi.org/',
    GIVECHARITY_URL: 'https://www.givecharity.org/',
    MHSGLOBAL_URL: 'https://www.mhsglobal.org/',
    INHERITINITIATIVE_URL: 'http://inheritinitiative.org/',
    CPANEL_STAGING_URL: 'https://staging.givecharity.org/cpanel',
    PRIVACY_POLICY_URL: 'privacy-policy',
    NOT_FOUND: 'page-not-found',
    AUTH: {
        LOGIN: 'login',
        FORGOT: 'forgot',
        CHANGE_PASSWORD: 'change-password',
        AUTH_INDICATION: 'auth-indication'
    },
    DEFAULT: {
        HOME: 'home',
        ABOUT: 'about',
        DONEE_REQUEST: 'request',
        CAMPAIGN_DETAILS: 'campaign-detail',
        BROWSE_CAMPAIGN: 'browse-campaign',
        BLOG: 'blog',
        CONTACT_US: 'contact-us',
        TERMS_SERVICE: 'term-service',
        PRIVACY_POLICY: 'privacy-policy',
        SIGNUP: 'signup',
        HOW_IT_WORKS: 'howitworks',
        PAYMENT_MESSAGE: 'paymentmessage',
        BLOGS: 'blogs',
        BLOG_URL: 'blog/:url',
        TAX_BENEFITS: 'tax-benefits',
        DONATE_URL: 'donate',
        EDUCATION_GRANTS: 'education-grants',
    },
    USER: {
        DASHBOARD: 'dashboard',
        DONEE_REQUEST_MODIFICATION_URL: 'request-modification',
        DONEE_REQUEST_LIST: 'request-list',
        MY_CAMPAIGN: 'my-campaign',
        MY_DONATION: 'my-donation',
        PAYOUT_REQUEST: 'payout-request',
        MY_PAYOUT: 'my-payout',
        MY_PAYOUT_VIEW: 'my-payout-view',
        MY_PROFILE: 'my-profile',
        DONATION_MODEL: 'donation-model',
        MY_SUBSCRIPTION: 'my-subscription',
    }
}





