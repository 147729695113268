import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { PasswordModule } from 'primeng/password';
import { login, User } from '../../interface/user';
import { AuthApiService } from '../../service/auth-api.service';
import { AuthService } from '../../auth.service';
import { RouterModule } from '@angular/router';
import { NgTemplateOutlet } from '@angular/common';
import { PageUrl } from '@gcWeb/shared/service/urlHelper';
type Status = 'SUCCESS' | 'FAILED' | 'CLOSED';

@Component({
  selector: 'gc-login-widget',
  standalone: true,
  imports: [ReactiveFormsModule, PasswordModule, CheckboxModule, RouterModule, NgTemplateOutlet, RouterModule],
  templateUrl: './login-widget.component.html',
  styleUrl: './login-widget.component.css',
  providers: [AuthApiService]
})
export class LoginWidgetComponent {
  @ContentChild("loginBody") public loginBody!: TemplateRef<any>;
  @Input() privacyUrl = '';
  @Input() termsUrl = '';
  @Input() signUp = '';
  @Input() forgotUrl = '';
  loading: boolean = false;
  submitted: boolean = false
  loginForm: FormGroup = new FormGroup({});
  errorMsg = '';
  staffLogin = PageUrl.CPANEL_STAGING_URL
  @Output() onComplete: EventEmitter<{ status: Status, data: User | null, message: string | null }> = new EventEmitter();
  constructor(private auth: AuthService,
    private api: AuthApiService) { }

  ngOnInit() {
    this.loginFormInit();
  }
  formCtrl(ctrl: string) {
    return this.loginForm.get(ctrl);
  }
  isValidCtrl(ctrl: string) {
    return this.formCtrl(ctrl)?.valid;
  }

  loginFormInit() {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
      remember: new FormControl(false)
    });
  }
  submitLoginForm() {
    this.doLogin();
  }

  close(status: Status, data: User | null, msg: string | null = null) {
    this.onComplete.emit({ status: status, data: data, message: msg });
  }
  doLogin() {

    this.submitted = true
    if (!this.loginForm.valid) {
      return
    }
    this.loading = true;
    const payload: login = this.loginForm.value;
    this.errorMsg = '';
    this.api.loginUser(payload).subscribe({
      next: (res: any) => {
        console.log('login success ', res)
        this.auth.setUser(res);
        this.close('SUCCESS', res);
      },
      error: (err: any) => {
        this.auth.logout();
        this.errorMsg = err.error?.error_description || 'Invalid Credential';
        this.close('FAILED', null, this.errorMsg);
        console.log('Error In Login ', err); this.loading = false;
      },
      complete: () => {
        this.loading = false;
      },
    })
  }
}
