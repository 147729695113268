import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { environment } from '@env';
import { AuthService } from '../auth.service';

export const apiInterceptor: HttpInterceptorFn = (req, next) => {
  const auth = inject(AuthService);

  const fullUrl = new RegExp(/^(http|https)/).test(req.url) ? true : false;

  const url = fullUrl ? req.url : `${environment.API_URL}${req.url}`;

  const reqD: any = {
    Authorization: req.headers.has('Authorization') ? req.headers.get('Authorization') : `Bearer ${auth.getToken()}`
  };

  const authR = req.clone({
    url: url.replace(/([^:])(\/\/+)/g, '$1/'),
    setHeaders: reqD
  });
  return next(authR);
};