import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UserProfile } from "@gcWeb/types";
import { topDonor } from "@shared/interface/campaign";
import { category } from "@shared/interface/category-interface";
import { GeneralCampaign, RecentDonation, RecentPayouts, SubscriptionUpdate } from "@shared/interface/Donor-interface";
import { StorageService } from "auth";
import { lastValueFrom, map, Observable, shareReplay } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  postUserDetailApi(email: any) {
    throw new Error("Method not implemented.");
  }
  categoryList: Array<category> = [];
  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) { }

  getCategory() {
    return new Promise((resolve, reject) => {
      const s = this.storageService.getItem("category");
      if (!s) {
        const sharedSource = this.http
          .get(`gc/campaign/getcategories`)
          .pipe(shareReplay());
        lastValueFrom(sharedSource).then((res: category[] | any) => {
          this.categoryList = res;
          this.storageService.setItem("category", res);
          resolve(res);
        });
      } else {
        resolve(s);
      }
    });
  }

  getKpiDetails(currencyId: string | number) {
    return lastValueFrom(this.http.get(`gc/campaign/kpi/${currencyId}`));
  }

  getOnGoingCampaign(currencyId: number) {
    return lastValueFrom(
      this.http.get(`gc/campaign/ongoingcampaignlist/${currencyId}`)
    );
  }
  getCompletedCampaign(currencyId: number) {
    return lastValueFrom(
      this.http.get(`gc/campaign/completedcampaignlist/${currencyId}`)
    );
  }
  getFBCampaignShareCount(url: any, fields: string, access_token: string) {
    return lastValueFrom(
      this.http.get(
        `https://graph.facebook.com/?id=${url}&fields=${fields}&access_token=${access_token}`
      )
    );
  }
  getUserPayoutDetail(id: number) {
    return this.http
      .get(`/gc/payout/getTotalPayoutByCampaignId/${id}`)
      .toPromise();
  }
  getMobileExtension() {
    return lastValueFrom(
      this.http.get(`projects/gc/public/mock/mobileExtension.json`)
    );
  }
  getAllCampaign(currencyId: number, categoryName: string, filterName: string) {
    return lastValueFrom(
      this.http.get(
        `gc/campaign/campaignlist/${currencyId}?categoryName=${categoryName}&filterName=${filterName}`
      )
    );
  }
  getActiveCampaign(currencyId: number) {
    return lastValueFrom(
      this.http.get("gc/campaign/ongoingcampaignlist/" + currencyId)
    );
  }

  getCampaignTopDonors(campaignId: number | string) {
    return lastValueFrom(this.http.get<topDonor[]>(`gc/campaign/topdonar/${campaignId}`));
  }
  getCampaignFileList(id: number | string): any {
    return this.http.get(`gc/file/campaignfilelists/${id}`);
  }

  isEmailUniqueAPI(email: string) {
    return this.http.get(`gc/users/isuniqueemail?useremail=${email}`);
  }

  isUniqueMobileAPI(mobile: string, isd: any) {
    return this.http.get(
      `gc/users/isuniquemobile?mobileno=${mobile}&isdcode=${isd}`
    );
  }
  relationshipListAPI() {
    return lastValueFrom(this.http.get(`gc/donee/relationshiplists`));
  }

  addDoneeAPI(data: any) {
    return this.http.post(`gc/donee/adddonee`, data);
  }
  uploadFileAPI(data: any) {
    return this.http.post(`gc/file/uploadmultiple`, data);
  }

  uploadDoc(data: FormData) {
    return lastValueFrom(this.http.post<{ documentUrl: string }>('gc/file/uploaddoc', data));
  }


  getPayoutlist(agencyid = null) {
    const apiurl = agencyid ? "/" + agencyid : "";
    return this.http.get(`gc/payout/payoutlist${apiurl}`);
  }
  getPayoutlistByCampagin(campaginId = null) {
    return this.http.get(`gc/payout/getPayoutStatics/${campaginId}`);
  }

  deletePayout(payoutId = null, reason = "") {
    return this.http.get(`gc/payout/delete/${payoutId}/${reason}`);
  }

  getMyPayoutRequest(id: string) {
    return this.http.get(`gc/payout/payoutlistByUser/${id}`);
  }
  getMyPayoutListByDoneeId(id: string) {
    return this.http.get(`gc/payout/payoutlistBydoneeId/${id}`);
  }

  // need to pas whole data object while update the request
  //Auth Required
  getRequestDetailsByIDAPI(data: any) {
    return this.http.get(`gc/donee/getrequestdetails/${data}`);
  }
  //get User Details
  getUserAPI(data: string) {
    return this.http.post(`gc/users/getuser?username=${data}`, data);
  }
  // get user details
  getUserDetailApi(username: string): Promise<UserProfile> {
    return lastValueFrom(
      this.http.post<UserProfile>(`gc/users/getuser?username=${username}`, null)
    );
  }
  //update user
  // updateUser(userId: string): Promise<UserProfile> {

  //   return lastValueFrom(this.http.post(`gc/users/updateuser/{userId}`));
  // }
  updateUserAPI(userId: string | number, payload: any): Observable<any> {
    return this.http.post(`gc/users/updateuser/${userId}`, payload);
  }

  deleteUserDocument(documentId: number, userId: any) {
    return this.http.delete(`/gc/file/deletedoc/${documentId}/${userId}`).toPromise();
  }

  //get document list
  getDocumentTypeAPI<T>(): Promise<T> {
    return lastValueFrom(this.http.get<T>(`/gc/users/getdocumenttypelist`))
  }
  // postDocumentTypeAPI(payload: { key: string }): Promise<DocumentType[]> {
  //   return lastValueFrom(this.http.post<DocumentType[]>(`${this.baseUrl}/getdocumenttypelist`, payload));
  // }

  //doneeFile List
  getFileListAPI(data: any) {
    return this.http.get(`gc/file/doneefilelists/${data}`);
  }

  //delete image from server
  deleteFileAPI(data: any) {
    return this.http.delete(`gc/file/delete/${data}`);
  }

  //update Donee Request
  updateDoneeRequest(req_id: string, data: any) {
    return this.http.post(`gc/donee/updatedoneerequest/${req_id}`, data);
  }

  getAllDoneeRequestAPI(agencyId: any) {
    return this.http.get(`gc/donee/requestlistbyagency/${agencyId}`);
  }

  updateDoneeRequestStatusAPI(
    status: any,
    userId: any,
    doneeRequestId: any,
    reason: any
  ) {
    if (reason) {
      return this.http.post(
        `gc/donee/updatedoneeRequestStatus?requestStatus=${status}&donationReqId=${doneeRequestId}&userId=${userId}&reason=${reason}`,
        null
      );
    } else {
      return this.http.post(
        `gc/donee/updatedoneeRequestStatus?requestStatus=${status}&donationReqId=${doneeRequestId}&userId=${userId}`,
        null
      );
    }
  }

  //get List Donee Request
  getDoneeRequestList(id: any, currencyId: any) {
    return this.http.get(`gc/donee/getdoneerequestlist/${id}/${currencyId}`);
  }

  getDoneeCampaign(id: any, currencyId: any) {
    return this.http.get(`gc/campaign/doneecampaignlist/${id}/${currencyId}`);
  }

  getMyDonationList(id: any, currencyId: any) {
    return this.http.get(`gc/donee/mydonations/${id}/${currencyId}`);
  }

  getUserDocumentList<T>(userId: number) {
    return lastValueFrom(
      this.http.get<T>(`gc/file/userdocumentlist/${userId}`)
    );
  }

  addSponsorAPI(data: any) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return lastValueFrom(this.http.post(`sponsor/generaterequest`, data, {
      headers: headers,
    }));
  }

  getDonationReceipt(donationId: any, fileName: any) {
    return lastValueFrom(
      this.http
        .get(`gc/report/pdfreceipt/${donationId}`, { responseType: "blob" })
        .pipe(
          map((data: any) => {
            this.downloadFile(data, fileName);
            return data;
          })
        )
    ).catch((error: any) => {
      return error;
    });
  }

  downloadFile(data: any, fileName: string) {
    var link = document.createElement("a");
    link.setAttribute("style", "display: none");
    link.href = window.URL.createObjectURL(data);
    link.download = fileName;
    link.click();
    link.remove();
  }

  payoutCreate(data: any) {
    return lastValueFrom(
      this.http.post(`gc/payout/create`, data, { responseType: "text" })
    );
  }

  getDoneedetailsList(id: string) {
    return this.http.get(`gc/campaign/admin/donationlistbyAgency/${id}`);
  }
  updatePayout(data: any) {
    return this.http.post(`gc/payout/update`, data).toPromise();
  }
  deletePayoutFile(fileName: any) {
    return this.http.delete(`gc/file/deletepayoutdoc/${fileName}`);
  }
  getCampaignDetailByID(id: number | string | undefined, currencyId: string | number | undefined) {

    return lastValueFrom(
      this.http.post(`gc/campaign/campaigndetail/${id}/${currencyId}`, {})
    );
  }
  getPayoutByID(id: number | string) {
    return this.http.get(`gc/payout/getUserPayoutdetailById/${id}`).toPromise();
  }
  updateDonationStatus(data: any) {
    return lastValueFrom(this.http.post(`/gc/cardpayment/updatestatus`, data));
  }
  donateAPI(data: any) {
    return lastValueFrom(this.http.post(`/gc/cardpayment/pay`, data));
  }
  isInrCurrencyAllowedByCampaignId(campaignId: any) {
    return this.http.get(`/gc/campaign/allowedCurrency/${campaignId}`);
  }
  calculateFee(data: any) {
    return lastValueFrom(this.http.post(`/gc/cardpayment/calculateFee`, data));
  }
  getUserSubcription(data: any) {
    return lastValueFrom(this.http.post<SubscriptionUpdate>(`/gc/cardpayment/subscription`, data))
  }
  getUserSubcriptionUpdate(data: any) {
    return lastValueFrom(this.http.post<SubscriptionUpdate>(`/gc/cardpayment/updatesubscription`, data))
  }
  getGeneralCampaign() {
    return lastValueFrom(this.http.get<GeneralCampaign[]>(`/gc/campaign/getgeneralCampaignlist`))
  }

  getSavedBank(id: string | number | undefined, rcid: number) {
    return lastValueFrom(this.http.get(`/gc/payout/getpayoutBankdetail/${id}/${rcid}`));
  }

  submitContact(payload: any) {
    return lastValueFrom(this.http.post(`/contact/submit`, payload));
  }

  downloadPayoutDocument(payoutId: number, fileName: string) {
    return lastValueFrom(this.http.get(`/gc/file/downloadpayoutdoc/${payoutId}/${fileName}`, { responseType: 'blob' }).pipe(map((data: any) => {
      this.downloadFile(data, fileName);
      return data;
    })));
  }
  getRecentDonation(id: string | number) {
    return lastValueFrom(this.http.get<RecentDonation[]>(`/gc/cardpayment/recentDonationdetail/${id}`));
  }
  getRecentPayouts(id: string | number) {
    return lastValueFrom(this.http.get<RecentPayouts[]>(`/gc/payout/recentPayoutlist/${id}`));
  }

  getMySubscriptionList(userId: number) {
    return lastValueFrom(this.http.post<any[]>(`/gc/cardpayment/subscriptionlistByUser/${userId}`, {}));
  }
}
