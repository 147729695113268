import { Injectable, Type } from '@angular/core';
import { DialogPositionEnum } from '@gcWeb/types';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private dialogRefs: Map<string, DynamicDialogRef> = new Map();
  constructor(
    private dialogService: DialogService,
  ) { }

  openModal<T>(component: Type<T>, config?: DynamicDialogConfig, keyId: string = '1') {
    if (this.dialogRefs.has(keyId)) {
      console.error('Dialog Ref Already Exist With Same Key');
      return
    }
    const ref = this.dialogService.open(component, {
      position: config?.position || DialogPositionEnum.TOP,
      contentStyle: { ...{ overflow: 'auto' }, ...config?.contentStyle },
      breakpoints: config?.breakpoints || {
        '960px': '75vw',
        '640px': '90vw',
      },
      closeOnEscape: config?.closeOnEscape || false,
      ...config
    })
    this.dialogRefs.set(keyId, ref);
    return new Promise<boolean | any>((resolve) => {
      ref.onClose.pipe(take(1)).subscribe((result: any) => {
        this.deleteRef(keyId)
        resolve(result)
      })
    });
  }

  deleteRef(keyId: string) {
    if (this.dialogRefs.has(keyId)) {
      this.dialogRefs.delete(keyId);
    }
  }

  closeModal(data?: boolean | any, keyId: string = '1') {
    if (this.dialogRefs.has(keyId)) {
      const ref = this.dialogRefs.get(keyId);
      ref?.close(data);
      this.deleteRef(keyId)
    }
  }

}
