import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AlertService, ToastTypeEnum } from '@gcCore';
import { catchError, throwError } from 'rxjs';
import { AuthService } from '../auth.service';

enum HttpErrorEnum {
  UNAUTHORIZED = 401,
  INTERNAL_SERVER_ERROR = 500,
  BAD_REQUEST = 400,
  NOT_FOUND = 404
}

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  const alertService = inject(AlertService)
  const authService = inject(AuthService);
  return next(req).pipe(catchError(error => {
    switch (error.status) {
      case HttpErrorEnum.UNAUTHORIZED:
        alertService.showToast('Token expired, Please login again.', ToastTypeEnum.WARN);
        authService.logout();
        break;

      case HttpErrorEnum.BAD_REQUEST:
        if (error.message) {
          let msg = error?.error?.message || error.message;
          alertService.showToast(msg, ToastTypeEnum.WARN);
        }
        break;

      case HttpErrorEnum.INTERNAL_SERVER_ERROR:
        alertService.showToast('Internal Server Error', ToastTypeEnum.WARN);
        break;

      case HttpErrorEnum.NOT_FOUND:
        alertService.showToast('Not Found', ToastTypeEnum.WARN);
        break;
    }
    const error_msg = error.error.message || error.statusText;
    return throwError(() => error_msg);
  }));
};
