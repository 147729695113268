import { afterNextRender, afterRender, Inject, Injectable, InjectionToken } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { StorageService } from './service/storage.service';
import { User } from './interface/user';
import { gcConfig } from '@gcConfig';
import { Router } from '@angular/router';
import { PageUrl } from '@gcWeb/shared/service/urlHelper';

enum LocalDb {
  CURRENT_USER = 'currentUser',
  ROLE = 'roleName',
  USER_PROFILE_IMG = 'userProfileImage',
  LAST_ACTION = 'lastAction',
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: User | null = {} as User;
  public userBS: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  public userBSSubscription: Subscription = {} as Subscription;
  authToken: string = '';
  constructor(
    private router: Router,
    private storageServe: StorageService) {
    this.authToken = gcConfig.AUTH.TOKEN;
    afterNextRender(() => {
      this.setUserBS(this.getUser())
    })
  }

  private getUser() {
    const data = this.storageServe?.getItem(LocalDb.CURRENT_USER);
    return data as any
  }
  get currentUser() {
    return this.userBS.value
  }

  setUserBS(user: User) {
    this.userBS.next(user);
  }

  setUser(data: any) {
    let temp: any = {};
    data.modulePermission.forEach((m1: any) => {
      if (m1.parentId == null && !temp[m1.id]) {
        temp = m1.name;
      }
      //console.log("finalAccessfinalAccessfinalAccess",temp)      
    });
    this.storageServe.setItem(LocalDb.ROLE, temp);
    this.user = {} as User;
    let permittedModules = {} as any;
    this.user.email = this.storageServe.getItem(gcConfig.AUTH.EMAIL) || data.emailId;
    this.user.token = this.storageServe.getItem(this.authToken) || data.token;
    this.user.name = `${data.firstName} ${data.lastName}`;
    this.user.mobileNo = data.mobileNo;
    this.user.mobileExt = data.mobileExt;
    this.user.firstName = data.firstName;
    this.user.middleName = data.middleName;
    this.user.lastName = data.lastName;
    this.user.id = data.userId;
    this.user.agencies = data.agencies;
    this.user.roles = data.roles;
    this.user.userType = +data.userType;
    this.user.isPasswordReset = data.isPasswordReset;
    this.user.profileImageUrl = data.profileImageUrl;
    let rolesStr = '';
    this.user.roles.forEach((role: any) => {
      rolesStr = rolesStr + role.roleName + ', ';
    });
    this.user.rolesStr = rolesStr.slice(0, -2);
    this.user.permittedModules = permittedModules;
    this.storageServe.setItem(LocalDb.CURRENT_USER, this.user)
    this.setUserBS(this.user);
  }

  setToken(token: string) {

    this.storageServe.setItem(this.authToken, token);
  }

  logout(redirectUrl: string = PageUrl.AUTH.LOGIN): void {
    this.storageServe.removeItem(this.authToken);
    this.storageServe.removeItem(gcConfig.AUTH.EMAIL);
    this.storageServe.removeItem(LocalDb.ROLE);
    this.storageServe.removeItem(LocalDb.CURRENT_USER);
    this.storageServe.removeItem(LocalDb.USER_PROFILE_IMG);
    this.storageServe.removeItem(LocalDb.LAST_ACTION);
    this.userBS.next(null);
    this.user = null;
    if (redirectUrl)
      this.goToLogin(redirectUrl);
  }
  isAuthenticated(): boolean {
    return this.storageServe.getItem(this.authToken) ? true : false
  }
  getToken() {
    return this.storageServe.getItem(this.authToken) || '';
  }
  goToLogin(url: string) {
    this.router.navigateByUrl(url)
  }

}
