import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { AlertEvent, AlertId, AlertDataOptions, AlertEventType, AlertEventOptions } from '../interface/alert';

export enum ToastTypeEnum {
  SUCCESS = 'success',
  ERROR = 'error',
  WARN = 'warning'
}

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  public events$: Subject<AlertEvent>;

  create(alertId: AlertId, options?: AlertDataOptions) {
    this.events$.next({ type: AlertEventType.CREATE_ALERT, data: { alertId, options } });
  }

  clearAll(options?: AlertEventOptions) {
    this.events$.next({ type: AlertEventType.CLOSE_ALL, options });
  }

  constructor(private messageService: MessageService) {
    this.events$ = new Subject();
  }

  showToast(detail: string = "we couldn't save your changes", type: ToastTypeEnum, header?: any) {
    this.messageService.add({ severity: type, summary: header ? header : type, detail: detail, life: 3000, closable: true });
  }
  clearToast(id = '') {
    this.messageService.clear(id)
  }
  ngOnDestroy(): void {
    this.events$.complete();
  }
}
