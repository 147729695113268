import { Component } from '@angular/core';

@Component({
  selector: 'gc-core',
  standalone: true,
  imports: [],
  template: `
    <p>
      core works!
    </p>
  `,
  styles: ``
})
export class CoreComponent {

}
