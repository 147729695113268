import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { gcConfig } from '@gcConfig';

@Directive({
  selector: '[imageLoad]',
  standalone: true
})
export class ImageLoadDirective {
  @Input('imageLoad') public imgType: string | any;
  constructor(private renderer: Renderer2,
    private el: ElementRef) {
    this.el.nativeElement.classList.add('loading');
  }

  @HostListener('load') onLoad() {
    this.el.nativeElement.classList.remove('loading');
  }
  @HostListener('onImageError') onImageError() {
    this.imageReplace(this.el.nativeElement.getElementsByTagName('img')[0]);
  }

  @HostListener('error') onError() {
    this.imageReplace();
  }
  imageReplace(element: any = this.el.nativeElement) {
    const src = this.imgType == 'user' ? gcConfig.DEFAULT_USER_IMAGE : gcConfig.DEFAULT_CAMPAIGN_IMAGE;
    this.renderer.setAttribute(element, 'src', src);
    this.el.nativeElement.classList.remove('loading');
  }

}
