import { Injectable } from "@angular/core";
import { AlertService } from "./alert.service";

@Injectable({
    providedIn: 'root',
  })
export class Network {
    constructor(private alertServe : AlertService){}
    checkNetworkError(error: any): boolean {
        // if (error.networkError) {
        //   this.alertServe.create(AlertId.NETWORK_ERROR, { code: CustomError.NETWORK_ERROR });
        // }
    
        return !!error.networkError;
      }
}
