import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { gcConfig } from '@gcConfig';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { UserLocation, currency } from '../interface/user-location';
import { StorageService } from './storage.service';
import { Currencies } from '@shared/interface/Donor-interface';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  public userLocationBS: BehaviorSubject<UserLocation> = new BehaviorSubject<UserLocation>({} as UserLocation);
  public currenciesBS: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  allCurrency: currency[] = [] as currency[];
  public rollBackedDefaultCurrencyId = gcConfig.CURRENCIES.USD.ID;
  constructor(private http: HttpClient,
    private storageServe: StorageService) {
    this.allCurrency = this.transformToCurrency(gcConfig.CURRENCIES);
    this.storageServe.removeItem(gcConfig.AUTH.SELECTED_CURRENCY);
    this.getGEOLocation();
    // this.getCurrencies().then(res => map((data: any) => {
    //   if (data.isSelected) {
    //     this.defaultCurrencyIdFromAPI = data.currencyId;
    //   }
    //   return data;
    // })).toPromise().then((data: any) => {

    //   this.currenciesBS.next(data);
    //   //if (!localStorage.getItem(gcConfig.AUTH.SELECTED_CURRENCY)) {
    //   if (true) {
    //     this.getIpAddress().toPromise().then((res: any) => {
    //       this.ipAPIResponseObj = res;
    //       this.getGEOLocation(res['ip']).toPromise().then((res: any) => {
    //         this.locationAPIResponseObj = res;
    //         this.prepareCurrencyObj();

    //       }).catch((error: any) => {
    //         this.prepareCurrencyObj();
    //       });

    //     }).catch((error: any) => {
    //       this.prepareCurrencyObj();
    //     });
    //   } else {
    //     this.prepareCurrencyObj();
    //   }
    // }).catch((error: any) => {
    //   this.prepareCurrencyObj();
    // });
  }
  prepareCurrencyObj(loc: any) {
    const userLocation = loc,
      code: 'USD' | 'INR' = loc.currency.code || 'INR';
    userLocation.selectedCurrency = this.getCurrencyDataByCurrencyType(code);
    this.storageServe.setItem(gcConfig.AUTH.SELECTED_CURRENCY, userLocation.selectedCurrency)
  }

  setNewUserLocation(loc: any) {
    const userLocation = loc,
      code: 'USD' | 'INR' = loc.currency.code || 'INR';
    userLocation.selectedCurrency = this.getCurrencyDataByCurrencyType(code);
    this.setNewCurrency(code);
    this.setUserLocationBS(userLocation);
  }

  setNewCurrency(code: 'USD' | "INR" = 'INR') {
    const currency = this.getCurrencyDataByCurrencyType(code);
    this.storageServe.setItem(gcConfig.AUTH.SELECTED_CURRENCY, currency);
  }


  setUserLocationBS(userLocation: UserLocation) {
    this.userLocationBS.next(userLocation);
  }

  transformToCurrency(obj: { [key: string]: any }): currency[] {
    return Object.entries(obj).map(([key, value]) => ({
      currencyId: value.ID,
      currencyType: value.TYPE as 'INR' | 'USD', // assert type since we know the value is 'INR' or 'USD'
      currencySymbol: value.SYMBOL as '$' | '₹', // assert type since we know the value is '$' or '₹'
    }));
  }
  getCurrencyDataByCurrencyType(currencyType: 'USD' | 'INR' = 'INR'): currency {
    return this.allCurrency.find((c: currency) => c.currencyType == currencyType) || {} as currency;
  }
  getCurrencyDataByCurrencyId(currencyId: '1' | '2' = '1'): currency {
    return this.allCurrency.find((c: currency) => c.currencyId == currencyId) || {} as currency;
  }

  getGEOLocation() {
    // Update your api key to get from https://ipgeolocation.io
    lastValueFrom(this.http.get(`https://api.ipgeolocation.io/ipgeo?apiKey=${gcConfig.GEO_LOCATION_API_KEY}`)).then(res => {
      this.setNewUserLocation(res);
    });
  }

  getCurrencies<T>() {
    return lastValueFrom(this.http.get<T>(`/gc/currency/list`));
  }
}
