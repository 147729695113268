<div class="container-lg">
    <div class="row align-items-center">
        <div class="col-lg-6">
            <form class="wizard-form form_container_login" (keydown.enter)="submitLoginForm()" [formGroup]="loginForm">
                <!-- <div class="text-center">
                    <img src="images/logo.png" alt="Give charity logo" width="322" />
                    <h1 class="pt-5 pb-2">Login</h1>
                    <p class="text-center mb-5">Please enter your credentials to continue and start making a difference
                        today!</p>
                </div> -->
                <h1 class="d-block d-lg-none text-center pb-3">Login to GiveCharity</h1>
                @if(loginBody){
                <ng-container [ngTemplateOutlet]="loginBody" [ngTemplateOutletContext]="{form:loginForm}">
                </ng-container>
                } @else{
                <div class="form-group">
                    <input pInputText class="form-control required" formControlName="email" name="email" type="text"
                        placeholder="Email Address">
                    @if(!isValidCtrl('email')){
                    @if(formCtrl('email')?.errors?.['required'] && submitted){
                    <div class="text-danger text-right">Email is required.</div>
                    }
                    @if(formCtrl('email')?.errors?.['email'] && submitted){
                    <div class="text-danger text-right">Email Id is Invalid.</div>
                    }
                    }
                </div>
                <div class="form-group password-field mb-2">
                    <p-password id="password" inputStyleClass="form-control" styleClass="w-100" name="password"
                        formControlName="password" placeholder="Password" [feedback]="false" [toggleMask]="true" />
                    @if(!isValidCtrl('password')){
                    @if(formCtrl('password')?.errors?.['required'] && submitted){
                    <div class="text-danger text-right">Password is required.</div>
                    }
                    }
                </div>

                <div class="row pb-4">
                    <div class="col-sm-6 col-6">
                        <div class="custom-control custom-checkbox" style="display: flex; gap: 10px;">
                            <p-checkbox formControlName="remember" [value]="true" Class="custom-control-input" />
                            <label labelStyleClass="custom-control-label" class="mb-0" for="cb1">Remember me</label>
                        </div>
                    </div>
                    <div class="col-sm-6 col-6 text-right">
                        <a class="forgot-pass" [routerLink]="['/', forgotUrl]">Forgot password?</a>
                    </div>
                </div>
                <div class="text-center error">{{errorMsg}}</div>
                <div class="text-center">
                    <!-- <p-button [disabled]="!loginForm.valid" label="Login" severity="success" [raised]="true"
                        styleClass="width100 mt-3" size="large" [loading]="loading" (onClick)="submitLoginForm()" /> -->
                    <button class="btn btn-primary btn-lg w-100" type="button" [disabled]="!loginForm.valid"
                        (click)="submitLoginForm()">Login</button>
                </div>
                }
                <!-- closed body -->
                <div class="signUpCont">
                    <h1 class="text-center">Welcome to GiveCharity!</h1>
                    <p class="text-center mb-5">We're excited to have you join our community of changemakers. At
                        CrowdFunding, we empower individuals and organizations to bring their impactful ideas to life
                        through collective support. Your login will give you access to a world of innovative projects
                        and meaningful contributions.</p>
                    <div class="text-center mt-3">Are you a Staff? <a [href]="[staffLogin]">Staff
                            Login</a></div>
                    <a class="btn btn-lg btn-outline-primary w-100" [routerLink]="['/', signUp]">Sign up</a>
                    <!-- <div class="mt-3 terms">
                        By continuing, you agree to our <a [routerLink]="['/', termsUrl]">Terms of
                            Service</a>
                        and
                        <a [routerLink]="['/', privacyUrl]" title="Privacy Policy">Privacy Policy</a>
                    </div> -->
                </div>
            </form>
        </div>
        <div class="col-lg-6 d-none d-lg-block">
            <img src="images/loginImg.png" alt="Give charity logo">
        </div>
    </div>
    <!-- <div class="row gc_login_footer">
        <div class="col-md-6">Copyright 2024 GiveCharity. All rights reserved</div>
        <div class="col-md-6 footerLoginNav">
            <ul>
                <li><a [routerLink]="['/', termsUrl]">Terms of Service</a></li>
                <li><a [routerLink]="['/', privacyUrl]" title="Privacy Policy">Privacy Policy</a></li>
            </ul>
        </div>
    </div> -->
</div>