import { Component, Input } from '@angular/core';

@Component({
  selector: 'gc-public-breadcrumb',
  templateUrl: './public-breadcrumb.component.html',
  styleUrl: './public-breadcrumb.component.sass',
  standalone:true
})
export class PublicBreadcrumbComponent {
@Input() title!:string;
}
