import { environment } from "@env";

export const gcConfig = {
    ENCRYPT_PASSWORD: 'R0NoYXIyMDIwTElWUDYxMA==',
    DEFAULT_CAMPAIGN_IMAGE: 'images/post-1.jpg',
    DEFAULT_USER_IMAGE: 'images/post-1.jpg',
    AUTH: {
        TOKEN: 'token',
        ID: 'id',
        EMAIL: 'email',
        SELECTED_CURRENCY: 'selectedcurrency'
    },
    CURRENCIES: {
        USD: {
            ID: '2',
            TYPE: 'USD',
            SYMBOL: '$',
            DESCRIPTION: ''
        },
        INR: {
            ID: '1',
            TYPE: 'INR',
            SYMBOL: '₹',
            DESCRIPTION: ''
        },
    },
    GEO_LOCATION_API_KEY: '84330d5f2e43432ebd7e1f1721d34bf7',
    LIFE_CYCLE_HOOKS: {
        INIT: 'init',
        DESTROY: 'destroy'
    },
    INSTAMOJO_PAYMENT_STATUS: {
        CREDIT: 'Credit'
    },
    COMMON_STATUS: {
        PENDING: 'Pending',
        INPROCESS: 'In Process',
        PROCESSED: 'Processed',
        APPROVE: 'Approved',
        REJECT: 'Rejected',
        CANCEL: 'Cancel'
    },
    STRIPE_API_KEY: environment.production ? 'pk_live_W6sYkf6MSflFQsixk9sNr5W7003D8O4TrQ' : 'pk_test_oPt5pif8PRJQJEPEsMJXFA8p00tDZmhiPr',
    FACEBOOK_API_KEY: environment.production ? '650259658905259|071d1d97a5df68ac20e709ca9ba88c2f' : '684801672089159|ac6132351b4f2dfc9930009981a76326'
}