import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ChangePasswordRequest } from '../../interface';
import { AuthService } from '../../auth.service';
import { AuthApiService } from '../../service/auth-api.service';
import { ButtonModule } from 'primeng/button';
import { ModalService } from '@gcWeb/shared/service/modal.service';

@Component({
  selector: 'gc-change-password-widget',
  standalone: true,
  imports: [ReactiveFormsModule, ButtonModule],
  providers: [AuthApiService],
  templateUrl: './change-password-widget.component.html',
  styleUrl: './change-password-widget.component.scss'
})
export class ChangePasswordWidgetComponent implements OnInit {

  changePasswordform: FormGroup = {} as FormGroup;
  isSubmitted: boolean = false;
  isConfirmPasswordDirty: boolean = false
  passwordsMatching: boolean = false;
  loader: boolean = false;
  apiErrorMessage: string = ''
  constructor(private authService: AuthService, private authApi: AuthApiService, private modalService: ModalService) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.changePasswordform = new FormGroup({
      currentpassword: new FormControl('', [Validators.required]),
      newpassword: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{6,16}$/), this.matchValidator('confirmpassword', true), this.passwordValidator]),
      confirmpassword: new FormControl('', [Validators.required, this.matchValidator('newpassword')]),
    })
  }

  checkPasswords(pw?: string, cpw?: string) {
    this.isConfirmPasswordDirty = true;
    if (pw == cpw) {
      this.passwordsMatching = true;
    } else {
      this.passwordsMatching = false;
    }
  }

  isRequirementMet(controlName: string, requirement: 'minLength' | 'uppercase' | 'lowercase' | 'number' | 'character'
  ): boolean {
    const control = this.changePasswordform.get(controlName)
    const value = control?.value || '';
    switch (requirement) {
      case 'minLength':
        return /.{6,16}/g.test(value);
      case 'uppercase':
        return /[A-Z]/.test(value);
      case 'character':
        return /(?=.*\W)/.test(value);
      case 'lowercase':
        return /[a-z]/.test(value);
      case 'number':
        return /\d/.test(value);
      default:
        return false;
    }
  }

  passwordValidator(control: any) {
    const value = control.value;
    const valid = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{6,16}$/.test(value);
    return valid ? null : { invalidPassword: true };
  }

  submitForm() {
    this.isSubmitted = true;
    if (this.changePasswordform.valid) {
      this.loader = true;
      this.authApi.changePasswordAPI(this.apiPayload()).then((res) => {
        if (res) {
          this.authService.logout()
          this.apiErrorMessage = '';
          this.modalService.closeModal()
        }
      }).catch((err) => this.apiErrorMessage = err?.error)
        .finally(() => this.loader = false)
    }
  }

  matchValidator(
    matchTo: string,
    reverse?: boolean
  ): ValidatorFn {
    return (control: AbstractControl):
      ValidationErrors | null => {
      if (control.parent && reverse) {
        const c = (control.parent?.controls as any)[matchTo] as AbstractControl;
        if (c) {
          c.updateValueAndValidity();
        }
        return null;
      }
      return !!control.parent &&
        !!control.parent.value &&
        control.value ===
        (control.parent?.controls as any)[matchTo].value
        ? null
        : { matching: true };
    };
  }

  closeModal() {
    this.modalService.closeModal()
  }

  apiPayload() {
    const user = this.authService.currentUser;
    const { value } = this.changePasswordform,
      payload: ChangePasswordRequest = {
        changedPassword: value.newpassword,
        currentPassword: value.currentpassword,
        email: user?.email || ''
      }
    return payload;
  }
}


