import { isPlatformBrowser } from '@angular/common';
import { Injectable, InjectionToken, PLATFORM_ID, inject } from '@angular/core';

export const BROWSER_STORAGE = new InjectionToken<Storage>('Browser Storage', {
  providedIn: 'root',
  factory: () => localStorage,
});
declare const localStorage: any;

@Injectable({ providedIn: 'root' })
export class StorageService implements Storage {
  private storage!: Storage;
  private platformId: any = inject(PLATFORM_ID)
  constructor() {
    if (isPlatformBrowser(this.platformId)) {
      this.storage = localStorage;
    }
  }
  length: number = 0;
  key(index: number): string | null {
    throw new Error('Method not implemented.');
  }
  getItem(key: string) {
    const v = this.storage.getItem(key)
    return v ? JSON.parse(v) : v;
  }
  setItem(key: string, v: any) {
    const value = JSON.stringify(v);
    this.storage.setItem(key, value);
  }
  removeItem(key: string) {
    this.storage.removeItem(key);
  }
  clear() {
    this.storage.clear();
  }
}