<section class="section-hero">
    <div class="innerPageBanner test3">
        <div class="container">
            <div class="row">
                <div class="text-center col-12">
                    <h1>{{title}}</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="curve-shape">
        <svg xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" width="846px" height="875px">
            <defs>
                <linearGradient id="PSgrad_0" x1="0%" x2="100%" y1="0%" y2="0%">
                    <stop offset="0%" stop-color="rgb(112,90,228)" stop-opacity="1"></stop>
                    <stop offset="0%" stop-color="rgb(78,98,215)" stop-opacity="1"></stop>
                    <stop offset="100%" stop-color="rgb(118,190,233)" stop-opacity="1"></stop>
                </linearGradient>
            </defs>
            <path fill-rule="evenodd" opacity="0.4" fill="rgb(113, 180, 231)" d="M772.494,875.000 C772.494,875.000 1003.238,671.570 647.028,530.599 C647.028,530.599 385.842,375.193 552.111,250.572 C645.545,180.541 651.008,41.335 376.458,5.950 C376.458,5.950 223.512,-23.950 130.756,50.715 C130.756,50.715 0.060,131.198 -0.000,300.905 C-0.049,439.755 1.152,875.000 1.152,875.000 L772.494,875.000 Z"></path>
            <path fill="url(#PSgrad_0)" d="M772.494,875.000 C772.494,875.000 1003.238,671.570 647.028,530.599 C647.028,530.599 385.842,375.193 552.111,250.572 C645.545,180.541 651.008,41.335 376.458,5.950 C376.458,5.950 223.512,-23.950 130.756,50.715 C130.756,50.715 0.060,131.198 -0.000,300.905 C-0.049,439.755 1.152,875.000 1.152,875.000 L772.494,875.000 Z"></path>
        </svg>
    </div> -->
  </section>