<div class="container mt-5">
    <form [formGroup]="changePasswordform">
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label for="">Current Password</label><br>
                    <input type="password" formControlName="currentpassword" class="form-control">
                    @if(isSubmitted && changePasswordform.get('currentpassword')?.errors?.['required']){
                    <span class="error"> Current Password is Required</span>
                    }
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label for="">New Password</label><br>
                    <input #pw type="password" name="newPassword" formControlName="newpassword" class="form-control">
                    @if(isSubmitted && changePasswordform.get('newpassword')?.errors?.['required']){
                    <span class="error"> New Password is Required</span>
                    }
                    @if(isSubmitted && changePasswordform.get('newpassword')?.errors?.['pattern']){
                    <span class="error"> New Password should valid</span>
                    }
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label for="">Confirm Password</label><br>
                    <input #cpw (keyup)='checkPasswords(pw?.value, cpw?.value)' type="password" name="confirmPassword"
                        formControlName="confirmpassword" class="form-control"><br>
                    @if(isSubmitted && changePasswordform.get('confirmpassword')?.errors?.['required']){
                    <span class="error">Confirm Password is Required</span>
                    }

                    @if(!passwordsMatching && isSubmitted){
                    <span class="error">
                        Confirm Password did not match
                    </span>
                    }

                </div>
            </div>
            @if(isSubmitted && changePasswordform.get('')?.errors?.['required']){
            <span class="error">
                Password is Required
            </span>
            }
            <ul>
                <li [class.strikethrough]="isRequirementMet('newpassword', 'minLength')">Must contain least 6 element
                </li>
                <li [class.strikethrough]="isRequirementMet('newpassword', 'character')">Must contain least 1
                    characters(#)</li>
                <li [class.strikethrough]="isRequirementMet('newpassword', 'uppercase')">Must contain least 1 upper case
                    letter (A-Z)</li>
                <li [class.strikethrough]="isRequirementMet('newpassword', 'lowercase')">Must contain least 1 lowercase
                    letter (a-z)</li>
                <li [class.strikethrough]="isRequirementMet('newpassword', 'number')">Must contain least 1 number (0-9)
                </li>
            </ul>
        </div>
        @if(apiErrorMessage){
        <div class="form-group text-center mt-3">
            <span class="error ">{{apiErrorMessage}}</span>
        </div>
        }
        <div class="d-flex justify-content-center">
            <p-button label="Submit" class="mr-2" severity="success" (click)="submitForm()" [loading]="loader" />
            <p-button label="Cancel" severity="danger" (click)="closeModal()" [loading]="loader" />
        </div>
    </form>