export interface EventBus {
    id: string;
    date: Date;
    type: string;
    data?: unknown;
}
export enum EventBusType {
    TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR',
    CLOSE_SIDEBAR = 'CLOSE_SIDEBAR',
    FINISH_LOGOUT = 'FINISH_LOGOUT',
}
